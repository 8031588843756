@import 'common-flagship_tasting_room';

.welcomeMilkrunSection {
	margin-top: 20px;
	text-align: center;
}

.welcomeMsg {
	font-size: 32px;
	letter-spacing: 0.31px;
	line-height: 40px;
}

.customizeshipment-msg {
	font-size: 20px;
	letter-spacing: 0.19px;
	line-height: 24px;
	padding: 30px;
}

.continueBtn {
	align-items: center;
	display: flex;
	flex-flow: column;
	margin: 5px;
	padding: 5px;

	button {
		@include g-button_main;

		background-color: $color-secondary-accent;
		color: $color-primary-background;
		cursor: pointer;
		height: 48px;
		margin-bottom: 80px;
		margin-top: 32px;
		padding: 5px;
		width: 434px;

		@include media(sm) {
			width: 320px;
		}
	}
}

.tier-details {
	padding: 16px;
	text-align: center;

	.tier-details-title {
		font-size: 32px;
		font-weight: bold;
		letter-spacing: 0.31px;
		line-height: 40px;
		padding-bottom: 15px;
		padding-top: 72px;
	}

	.tier-description {
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0.15px;
		line-height: 24px;
		padding-top: 30px;
		text-transform: capitalize;

		@include media (sm) {
			padding-bottom: 24px;
		}
	}
}

.tier-tile-info {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 16px;
	padding: 16px;

	.tier-tile-details {
		border: 1px solid $color-secondary-accent !important;
		font-family: $font-main;
		font-size: 12px;
		min-height: 298px;
		text-align: center;
		width: 320px;

		@include media(sm) {
			height: auto;
			width: 240px;
		}

		.tier-tile {
			.tierName {
				color: $color-accent;
				font-family: $font-alt;
				font-size: 16px;
				font-weight: bold;
				height: 24px;
				letter-spacing: 0.5px;
				line-height: 24px;
				padding: 16px;
			}

			.tierQty {
				font-family: $font-alt;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: 0.5px;
				line-height: 24px;
				padding: 15px;
			}

			.tierPrice {
				font-family: $font-alt;
				font-size: 24px;
				font-weight: 400;
				letter-spacing: 0.6px;
				line-height: 40px;
				margin-top: 27px;
			}

			.tier-desc {
				border-top: 1px solid $color-shade_4;
				font-family: $font-main;
				font-size: 12px;
				margin: 20px;
				padding-top: 20px;
				text-align: left;
			}
		}
	}

	.change-membership {
		font-family: $font-alt;
		margin-bottom: 6px;
		margin-top: 30px;

		a {
			@include t-link_underlined;
		}
	}
}

.b-add_to_wishlist {
	&::before {
		background-color: $color-secondary-accent !important;
	}
}

.f-input_radio-label,
.f-input_checkbox-label {
	color: $color-primary !important;
	font-size: 16px !important;
	font-weight: 500;
	letter-spacing: 0.5px;
	line-height: 24px;
}

.btnAlign {
	margin-top: 40px;
}

.tier-tiles {
	padding: 8px;

	.tierTitle {
		font-family: $font-main;
		font-weight: bold;
		height: 24px;
		line-height: 24px;
		padding: 16px;
	}

	.tierQtyMemship {
		font-size: 14px;
		font-weight: bold;
		padding: 15px;
	}
}

.tier-list {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;

	@include media(sm) {
		display: block;
	}

	.b-carousel-track {
		@include media(md-up) {
			display: flex;
		}
	}

	.b-carousel-pagination_container {
		@include media(md-up) {
			display: none;
		}
	}

	.tier-tile-backdrop {
		background-color: $color-shade_5;
		border: 1px solid $color-label;
		margin: 20px 10px;
		position: relative;
		width: 208px;

		&.b-carousel-item {
			&:focus-within {
				border: 1px solid;
			}
		}

		.selected-pill {
			background: $color-primary;
			border-radius: 20px;
			color: $color-primary-background;
			display: none;
			left: 0;
			position: absolute;
			right: 0;
			top: -10px;
			width: 140px;

			@include icon(checkmark-plain,
			$icon-width: 20px,
			$icon-height: 20px) {
				display: inline-block;
				margin-right: 2px;
				vertical-align: text-top;
			}

			&::before {
				background-color: #fff;
			}
		}

		&.active {
			border-color: $color-primary;

			.selected-pill {
				display: block;
			}
		}
	}

	.tier-descMemship {
		border-top: 1px solid $color-shade_4;
		font-size: 12px;
		margin: 5px;
		padding-top: 20px;
		text-align: left;
	}

	.tierPriceMemship {
		font-size: 20px;
		margin-top: 27px;
	}

	.tierPercentMemship {
		font-size: 12px;
	}
}

.wcmembership-infotile {
	padding: 16px;
	text-align: center;
}

.wcproduct-selectionlist {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.wcproduct-tile {
	align-items: center;
	border: 1px solid $color-primary;
	display: flex;
	flex-flow: column;
	justify-content: space-around;
	line-height: inherit;
	margin: 8px;
	width: 320px;
}

.wcproduct-tile img {
	height: 144px;
	padding-bottom: 20px;
	padding-top: 8px;
}

.wcproduct-tile-info div {
	line-height: 16px;
	padding: 5px;
}

.wcproduct-tile-info {
	font-size: 12px;
	letter-spacing: 0.12px;
	line-height: 16px;
	margin-bottom: 12px;
	text-align: center;
}

.hidden {
	display: none;
}

.wcproduct-qtyselectionlist {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.quantity-pick-container {
		background: $color-secondary-accent;

		.radio__label {
			background-color: $color-secondary-accent;

			svg {
				color: $color-primary-background;
			}
		}

		.radio__input:checked + .radio__label {
			background-color: $color-primary-background;

			svg {
				color: $color-secondary-accent;
			}
		}
	}
}

.wcproductQuantity-tile {
	background-color: $color-secondary-accent;
	color: $color-primary-background;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 5px;
	text-align: center;
	width: 320px;
}

.wcproductQuantity-tile img {
	height: 144px;
	padding-bottom: 20px;
	padding-top: 8px;
}

.wcproduct-discountprice {
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.15px;
	line-height: 24px;
	text-align: center;
}

.wcproduct-actprice {
	font-size: 12px;
	text-decoration: line-through;
}

.wc-logo {
	height: 100%;
	padding-bottom: 30px;
	width: 100%;
}

.editbtn {
	align-items: center;
	display: flex;
	flex-flow: column;

	button {
		cursor: pointer;
		font: 16px/24px $font-alt;
		letter-spacing: 0.5px;
	}
}

// login form
.milkrun-login {
	border: 1px solid $color-overlay-background;
	display: flex;
	flex-direction: column;
	margin: 56px 18px;
	padding: 32px 24px;

	@include media(md-up) {
		flex-flow: row wrap;
		justify-content: space-around;
		margin: 48px 96px;
		padding: 40px 0;

		.login-account-section,
		.create-account-section {
			flex-basis: 40%;
			padding: 0 10px;
		}
	}

	.line-item-divider {
		margin: 32px 0;

		@include media(md-up) {
			margin: 0;
		}
	}

	.login-account-title,
	.create-account-title {
		color: $color-shade_9;
		font-family: $font-alt;
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0.5px;
		line-height: 32px;
		padding-bottom: 8px;

		@include media(md-up) {
			padding-bottom: 24px;
		}
	}

	.login-account-desc,
	.create-account-desc {
		font-family: $font-main;
		font-size: 16px;
		line-height: 24px;
		padding-bottom: 24px;
	}

	.b-login_helpers {
		align-items: center;
		display: flex;
		justify-content: space-between;

		.f-input_checkbox {
			&-label {
				color: $color-shade_4 !important;
				font-family: $font-alt;
				font-size: 12px !important;
				font-weight: 300;
			}
		}

		.f-field {
			margin-bottom: 0;
		}
	}

	.b-login_helpers-forgot_link {
		color: $color-accent;
		font-family: $font-main;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0.5px;
		line-height: 24px;
		margin-bottom: 32px;
		text-decoration: none;
		text-transform: capitalize;
	}

	.b-form-btn_main {
		height: 48px;
	}

	.continueBtn button {
		font-family: $configuration-button-font_style;
		font-size: 16px;
		letter-spacing: 0.5px;
		line-height: 24px;
		width: 320px;
	}
}

.mr-login-form {
	display: flex;
	justify-content: center;
}

.b-form-btn_main {
	font-family: $font-alt;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.b-breadcrumbs {
	&-item {
		align-items: baseline;

		&:not(.m-current) {
			@include media(md-up) {
				@include icon('arrow-right', 8px, 8px, after) {
					background: $color-breadcrumb-separator_bg;
					margin: 2px 15px 0;
				}
			}
		}
	}
}

.b-breadcrumbs-list {
	color: $color-secondary-accent !important;
	font-size: 16px !important;
	letter-spacing: 0.5px;
	line-height: 24px;

	&.m-current {
		color: $color-accent !important;
	}
}

.redirectshipping {
	background-color: $color-secondary-accent;
	color: $color-primary-background;
	display: inline-block;
	font: 16px/24px $configuration-button-font_style;
	letter-spacing: 0.5px;
	margin: 0 16px;
	padding: 16px 42px;
	text-decoration: none;
	text-transform: uppercase;

	&:hover {
		text-decoration: none;
	}
}

// swap popup
.swap-dialog-header {
	display: flex;
	justify-content: flex-end;
	margin: 40px 96px;

	button {
		color: $color-svg-icon;
		margin: 32px 30px;
	}
}

.edit-shipment-dialog-window-swap {
	background-color: $color-primary-background;
	height: 100%;
	width: 100%;
}

.edit-shipment-dialog {
	.edit-shipment-hidden {
		display: none;
	}

	#shipment-date-form-para-container > p.info-title {
		margin-bottom: 40px;
	}

	#shipment-date-form-para-container > p:nth-child(1) {
		margin-bottom: 10px;
	}

	.edit-shipment-para {
		color: $color-shade_6;
		font: $configuration-t_heading_5-font;
		margin-bottom: 25px;
	}

	.edit-shipment-heading {
		font-family: $font-alt;
		font-size: 24px;
		font-weight: 500;
		height: 64px;
		letter-spacing: $configuration-category_navigation-link-letter_spacing;
		line-height: 24px;
		text-align: center;
	}

	.b-user_greeting-message {
		white-space: normal;
	}

	.edit-shipment-last-date {
		text-align: left;
	}

	.edit-shipment-dialog-window-reschedule {
		background-color: $color-primary-background;
		height: 748px;
		margin: 128px 168px;

		@include media(md-up) {
			width: 1104px;
		}

		@media screen and (max-width: 767px) {
			margin: 0;
		}

		.edit-shipment-dialog-header {
			display: flex;
			justify-content: flex-end;

			button {
				margin: 32px 30px;
			}
		}

		.edit-shipment-dialog-body {
			@include media(md-up) {
				margin: 160px 336px;
			}

			.form-modal-container-inner {
				display: flex;
				justify-content: center;
				text-align: center;

				.edit-shipment-dialog-form-before-submitting {
					max-width: 880px;

					.f-input_text-field {
						padding: 20px;
					}

					.edit-shipment-flex {
						display: flex;
						flex-direction: row;
					}

					.shipment-error-message {
						color: $color-footer-alt;
						font-size: 12px;
						letter-spacing: 0.12px;
						line-height: 16px;
						margin-bottom: 25px;
					}
				}

				.edit-shipment-dialog-form-after-submitting {
					max-width: 880px;
				}
			}
		}
	}
}

@include media(sm) {
	.edit-shipment-dialog-window-reschedule {
		background-color: $color-primary-background;
		height: 648px;
		margin: 65px 18px;
		width: 376px;
	}

	.b-dialog-body-edit-shipment {
		margin: 80px 32px;
	}
}

// wineclub registration form
.mr-register-form {
	display: flex;
	justify-content: center;
	margin: 0 18px;

	.register-section {
		border: 1px solid $color-shade_5;
		padding: 32px 24px;

		@include media(md-up) {
			margin: 48px 102px;
			padding: 56px 96px;
		}
	}

	.b-account_benefits {
		margin-bottom: 32px;

		@include media(md-up) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		.b-account_benefits-title {
			font: 24px/40px $font-alt;
			letter-spacing: 0.6px;
			margin-bottom: 16px;
			text-align: center;
			width: 100%;
		}

		.b-account_benefits-list {
			list-style: disc;

			@include media(md-up) {
				width: 75%;
			}
		}

		.b-account_benefits-item {
			color: $color-shade_6;
			font: 16px/24px $font-main;
			letter-spacing: 0.5px;
			list-style-position: inside;
		}
	}

	.register-form-elements {
		display: flex;
		flex-wrap: wrap;

		@include media(md-up) {
			&_part1,
			&_part2 {
				flex-basis: 48%;
			}

			&_part1 {
				margin-right: 16px;
			}
		}
	}

	.button-section {
		margin-top: 56px;

		@include media(md-up) {
			margin: auto;
			width: 50%;
		}
	}

	.b-code_field-caption {
		margin-bottom: 40px;

		.b-code_field-link {
			color: $color-secondary-accent;
			font-family: $font-main;
			font-size: 16px;
			text-decoration: underline;
			text-transform: uppercase;
		}
	}
}

.tier-info {
	.membership-des {
		margin-bottom: 48px;

		@include media(sm) {
			margin-bottom: 32px;
		}

		h1 {
			font: 24px/48px $font-alt;
			letter-spacing: 0.5px;
			margin-bottom: 32px;
		}
	}
}

.managePreferenceModal,
.manageNoPreferenceModal,
.manageShipmentMethodModal {
	.b-dialog.m-quick_view .b-dialog-window {
		width: 100%;
	}

	.wineclub-modal.m-quick_view .b-dialog-window .b-dialog-body {
		width: 95%;
	}

	#sendproductquantity {
		display: none;
	}

	.tier-preferences {
		.wcmembership-infotile {
			margin: 30px 0;

			@include media(xl) {
				margin: 52px 0 30px;
			}

			h2 {
				font-size: 24px;
				font-weight: 300;
				letter-spacing: 0.23px;
				line-height: 32px;
				margin: 20px 0;
				text-align: center;
			}

			p {
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 0.15px;
				line-height: 24px;
				text-align: center;
			}
		}
	}

	.b-dialog-footer {
		align-items: center;
		flex-flow: column;
		justify-content: center;

		@include media(xl) {
			align-items: center;
			flex-flow: column;
			justify-content: center;
		}

		button.b-account_info_tile-link {
			@include t-link_1;

			color: $color-link;
			margin-top: 30px;

			&.no-border {
				border: none;
			}

			&:disabled {
				animation: none !important;
				background-image: linear-gradient(currentColor, $color-link__disabled);
				color: $color-link__disabled;
				cursor: default;
			}
		}

		.b-form-btn_main {
			width: 295px;

			&:disabled {
				background-color: $color-shade_4;
				border-color: $color-shade_4;
				color: $color-primary-background;
			}
		}
	}

	.mem-info,
	.cancel-membership-alert {
		h2,
		.heading {
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 0.23px;
			line-height: 32px;
			margin-bottom: 8px;
			text-align: center;
		}

		p,
		.membership-cancel-help-text {
			font-size: 16px;
			font-weight: 300;
			letter-spacing: 0.15px;
			line-height: 24px;
			text-align: center;
		}

		.membership-cancel-help-text {
			margin: 16px 0;
		}

		.buttons {
			margin: 24px 0;
		}

		button {
			font-size: 16px;
			font-weight: bold;
			letter-spacing: 0.15px;
			line-height: 16px;
			margin: 24px 0;
			max-width: 432px;
			text-align: center;

			&.btn-main {
				background-color: $color-button_main-background;
				border-color: $color-button_main-border;
				color: $color-button_main-text;

				&:hover {
					background-color: $color-button_main__hover-background;
					border-color: $color-button_main__hover-border;
					color: $color-button_main__hover-text;
				}
			}
		}

		.small-text {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 16px;
			text-align: center;
		}
	}
}

.memership-updated {
	display: none;
}

.edit-menu {
	text-align: center;
}

.tier-detail {
	text-decoration: underline;
}

.arrow {
	border: solid $color-primary;
	border-width: 0 3px 3px 0;
	display: inline-block;
	font-size: 12px;
	padding: 3px;
}

.arrow-up {
	transform: rotate(-135deg);
}

/* radio button */
.quantity-pick-container {
	background: $color-primary;
	display: inline-flex;
	height: 40px;
	justify-content: space-around;
	overflow: hidden;
	padding: 0 16px;
	width: 100%;

	> div {
		position: relative;
	}
}

.quantity-options {
	position: relative;
}

.radio__input {
	left: 50%;
	opacity: 0;
	position: absolute;
	top: 50%;
}

.radio__label {
	background-color: $color-primary;
	border: 1px solid $color-primary-background;
	border-radius: 20px;
	color: $color-primary-background;
	cursor: pointer;
	display: flex;
	justify-content: center;
	margin: 5px;
	padding: 3px 5px;
	text-align: center;
	transition: 0.25s;
	width: 84px;
}

.radio__label span {
	font-size: 16px;
	height: 24px;
	line-height: 24px;
	width: 25px;
}

.radio__input:checked + .radio__label {
	background-color: $color-primary-background;
	color: $color-primary;
}

.wcmembershipname {
	font-size: 32px;
	font-weight: bold;
	letter-spacing: 0.31px;
	line-height: 40px;
	padding-bottom: 15px;
	padding-top: 35px;
}

.wcmembership-description {
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0.15px;
	line-height: 24px;
}

.selctiontext-info {
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 0.23px;
	line-height: 32px;
	margin-bottom: 15px;
	margin-top: 55px;
	text-align: center;
}

.selectionoption-msg {
	font-size: 20px;
	letter-spacing: 0.19px;
	line-height: 24px;
	margin: 15px 0;
	text-align: center;
}

.productPreference-carouselMobile {
	@include media(md-up) {
		display: none;
	}

	.wcproduct-tile {
		margin: 8px auto;
	}
}

.productpreference-desktop {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	@include media(sm) {
		display: none;
	}
}

.wcproduct-tileimage {
	height: 144px;
	margin-top: 8px;

	img {
		height: 100%;
	}
}

.wcproduct-name {
	font: 700 16px/24px $font-alt;
	letter-spacing: 0.5px;
	padding: 3px;

	a {
		line-height: 24px;
		text-decoration: underline;
	}
}

.wcproduct-description {
	font-family: $font-main;
}

.wcproduct-desc2 {
	font-family: $font-main;
}

.productPreference-carouselMobile {
	.b-carousel-pagination_container {
		align-items: center;
		display: flex !important;
		justify-content: center;
		margin-bottom: 32px;
		margin-top: 39px;
	}

	.b-carousel-pagination_control {
		background-color: $color-carousel_pagination_control;
		height: 8px;
		margin: 3.5px;
		width: 8px;
	}

	.b-carousel-pagination_control.m-current {
		background-color: $color-carousel_pagination_control__active;
	}

	.b-carousel-pagination_control:first-child,
	.b-carousel-pagination_control:last-child {
		height: 5px;
		width: 5px;
	}

	.wcproduct-actprice-withoutstrikethrough {
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0.15px;
		line-height: 24px;
		text-align: center;
	}
}

.selectbtn {
	background-color: $color-primary-background;
	border: 0;
	border-top: 1px solid $color-primary;
	color: $color-primary !important;
	margin-top: auto;
	padding: 15px 10px;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.selected {
	background-color:transparent !important;
	border: 3px solid $color-secondary-accent;
	color: $color-primary !important;
}

.removebtn {
	background-color: $color-primary;
	border: 0;
	border-top: 1px solid $color-primary-background;
	color: $color-primary-background;
	height: fit-content;
	margin-top: 7px;
	padding: 15px 10px;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}

.preferenceSelectionType {
	.wcproduct-tile {
		position: relative;
	}

	.selectbtn {
		bottom: 0;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
	}
}

.membership-loader {
	animation: 3s ease 0s normal infinite fadein;
	margin: 0 auto;
	width: 75px;
	-webkit-animation: 3s ease 0s normal infinite fadein;
}

/* icepack */
$color-grey: #737171;

.icepk_msg {
	color: $color-grey;
	font-size: 14px;
	font-weight: 600;
	margin-top: 10px;
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	66% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadein {
	0% {
		opacity: 0;
	}

	66% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.remove-shipment {
	margin-top: 16px;
	text-align: center;

	&_button.b-login_helpers-forgot_link {
		color: $color-accent;
		text-transform: capitalize;
	}
}

.b-login_helpers-forgot_link {
	&.b-login_forgot_cancel_membership {
		margin-top: 30px;
	}
}
