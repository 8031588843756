@import 'account-flagship_tasting_room.scss';

.tasting-reservation-cover {
	padding: 0 !important;

	h1 {
		color: $color-primary-background;
		font-size: 32px;
		letter-spacing: 0.31px;
		line-height: 40px;
		max-width: 656px;
		text-align: center;
	}

	h2,
	h3 {
		padding-top: 56px !important;
	}
}

img.b-banner_2-background_image {
	@include media(md-down) {
		height: 344px !important;
	}
}

.tasting-reservation-info {
	li {
		font-size: 16px;
		font-weight: 300;
		letter-spacing: 0.15px;
		line-height: 24px;
	}

	@include media(md-down) {
		--pd-grid_gap-sm: 0 !important;
		padding-bottom: 56px !important;
	}
}

.tasting-reservation-info h2,
.tasting-reservation-col-even h2,
.tasting-reservation-col-odd h2 {
	color: $color-primary;
	font-size: 32px;
	letter-spacing: 0.31px;
	line-height: 48px;
	margin-bottom: 40px;
}

.tasting-reservation-info h3 {
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.15px;
	line-height: 24px;
	margin-bottom: 40px;
}

.tasting-reservation-col-odd {
	background: $color-shade_2;
}

.description p {
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0.15px;
	line-height: 24px;
	margin-bottom: 29px;
}

.tasting-reservation {
	padding: 72px 0;

	@include media(sm) {
		padding: 0;

		h2 {
			padding-top: 32px;
		}
	}

	.b-text_box {
		@include media(md-down) {
			margin-top: 10px;
		}
	}

	.g-button_main {
		margin-left: 10%;

		@include media(sm) {
			margin-left: 0%;
		}
	}

	.b-pd_picture-image {
		object-fit: cover;
	}
}

.tasting-reservation-col-odd,
.tasting-reservation-col-even {
	background: $color-shade_2;

	@include media (md-down) {
		padding-bottom: 18px !important;
	}
}

.tasting-reservation-col-even,
.tasting-reservation-col-odd {
	@include media(md-down) {
		--pd-grid_gap-sm: 0 !important;
	}
}

.experience-main {
	@include media(md-down) {
		padding-bottom: 50px !important;
	}
}
